@font-face {
    font-display: swap;
    font-family: Viessmann Web;
    font-style: normal;
    font-weight: 300;
    src: url(../assets/fonts/ViessmannWeb-Light.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web;
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/ViessmannWeb-Regular.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web;
    font-style: normal;
    font-weight: 500;
    src: url(../assets/fonts/ViessmannWeb-Medium.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web;
    font-style: normal;
    font-weight: 600;
    src: url(../assets/fonts/ViessmannWeb-SemiBold.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web;
    font-style: normal;
    font-weight: 700;
    src: url(../assets/fonts/ViessmannWeb-Bold.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Wide;
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/ViessmannWeb-WideRegular.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Wide;
    font-style: normal;
    font-weight: 600;
    src: url(../assets/fonts/ViessmannWeb-WideSemiBold.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Wide;
    font-style: normal;
    font-weight: 700;
    src: url(../assets/fonts/ViessmannWeb-WideBlack.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Condensed;
    font-style: normal;
    font-weight: 300;
    src: url(../assets/fonts/ViessmannWeb-CondLight.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Condensed;
    font-style: normal;
    font-weight: 400;
    src: url(../assets/fonts/ViessmannWeb-CondRegular.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Condensed;
    font-style: normal;
    font-weight: 500;
    src: url(../assets/fonts/ViessmannWeb-CondMedium.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Condensed;
    font-style: normal;
    font-weight: 600;
    src: url(../assets/fonts/ViessmannWeb-CondSemiBold.woff2) format('woff2');
}

@font-face {
    font-display: swap;
    font-family: Viessmann Web Condensed;
    font-style: normal;
    font-weight: 700;
    src: url(../assets/fonts/ViessmannWeb-CondBold.woff2) format('woff2');
}
