@import './scss/colors';
@import './scss/theme';
@import './fonts/viessmann';
@import './fonts/material-icons';

* {
    box-sizing: border-box;
}

@page {
    margin-top: 2cm;
}

:root {
    --default-font: Viessmann Web, sans-serif;
}

html,
body {
    height: 100% !important;
    margin: 0;
    padding: 0;
    font-family: var(--default-font);
}

a {
    color: $color-black;
    font-weight: 600;
    text-decoration: none;
}

button.mat-raised-button {
    &:hover {
        background-color: $color-primary-light;
        &.mat-primary {
            background-color: $color-primary-grey;
        }
    }
}

button.mat-mdc-button,
button.mat-mdc-raised-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    border-radius: 160px;
    padding: 0 20px;
    letter-spacing: 0 !important;
}

.success-snackbar {
    background-color: $color-primary-dark;
}

.error-snackbar {
    background-color: $color-error;
}

p {
    padding-right: 0 !important;
    padding-left: 0 !important;
}
